<!--
 * @Author: your name
 * @Date: 2020-04-23 16:12:30
 * @LastEditTime: 2020-12-31 11:26:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/useHelp/index.vue
-->

<template>
  <div class="join-promotion">
    <!-- pc -->
    <section class="join-list">
      <img class="temporary" src="../../assets/images/joinPromotion/join01.jpg">
      <img class="temporary" src="../../assets/images/joinPromotion/join02.jpg">
      <img class="temporary" src="../../assets/images/joinPromotion/join03.jpg">
      <img class="temporary" src="../../assets/images/joinPromotion/join04.jpg">
      <img class="temporary" src="../../assets/images/joinPromotion/join05.jpg">
      <img class="temporary" src="../../assets/images/joinPromotion/join06.jpg">
      <img class="temporary" src="../../assets/images/joinPromotion/join07.jpg">
    </section>
    <div id="boxFixed" class="join-us" :class="{'is_fixed' : isFixed}">
      <div class="join-center">
        <h4>联系我们</h4>
        <div class="input-to">
          <el-input v-model="inputName" class="name-input" maxlength="100" placeholder="姓名" />
          <el-input v-model="inputTel" class="tel-input" maxlength="11" placeholder="请输入手机号" />
          <!-- <el-input v-model="inputCity" class="intention" maxlength="6" placeholder="意向城市" /> -->
          <el-cascader
            ref="cascader"
            v-model="inputCity"
            clearable
            :options="areaData"
            :props="areaProps"
            class="intention"
            placeholder="所在地区"
          />
        </div>
        <el-button type="primary" @click="contactUs(1)">
          提交
        </el-button>
      </div>
    </div>
    <!-- mb -->
    <section class="join-list-mb">
      <img class="temporary" src="../../assets/images/moblie/join01.png">
      <img class="temporary" src="../../assets/images/moblie/join02.png">
      <img class="temporary" src="../../assets/images/moblie/join03.png">
      <img class="temporary" src="../../assets/images/moblie/join04.png">
      <img class="temporary" src="../../assets/images/moblie/join05.png">
      <img class="temporary" src="../../assets/images/moblie/join06.png">
      <img class="temporary" src="../../assets/images/moblie/join07.png">
    </section>
    <div class="join-center-mb">
      <h4>联系我们</h4>
      <div class="input-to">
        <div class="input-box">
          <span>姓名</span>：
          <el-input v-model="inputName" class="name-input com-input" maxlength="100" placeholder="请输入姓名" />
        </div>
        <div class="input-box">
          <span>手机号</span>：
          <el-input v-model="inputTel" class="tel-input com-input" maxlength="11" placeholder="请输入手机号" />
        </div>
        <div class="input-box">
          <el-cascader
            ref="cascader"
            v-model="inputCity"
            clearable
            :options="areaData"
            :props="areaProps"
            class="intention com-input"
            placeholder="所在地区"
          />
        </div>
        <!-- <el-input v-model="inputProvince" class="intention" placeholder="意向省份" />
        <el-input v-model="inputCity" class="intention" placeholder="意向城市" /> -->
      </div>
      <el-button type="primary" @click="contactUs(2)">
        提交
      </el-button>
    </div>
  </div>
</template>

<script>
import { contactUs, getArea } from '@/api/joinUs.js';

export default {
  name: 'Product',
  data() {
    return {
      areaProps: {
        value: 'id',
        label: 'name',
        children: 'children'
      },
      areaData: [],
      inputName: '',
      inputTel: '',
      inputProvince: '',
      inputCity: '',
      isFixed: false,
      offsetTop: 0
    };
  },
  mounted() {
    this.areaHandle();
    window.addEventListener('scroll', this.initHeight);
    this.$nextTick(() => {
      this.offsetTop = document.querySelector('#boxFixed').offsetTop;
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    areaHandle() {
      getArea().then((res) => {
        this.areaData = res.data;
      });
    },
    initHeight() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.isFixed = scrollTop > this.offsetTop;
    },
    // 提交加盟信息
    contactUs(source) {
      const pattern = new RegExp(/^0{0,1}(12[0-9]|13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/);
      if (!this.inputName) {
        this.$message.warning('请输入姓名');
        return false;
      } else if (!this.inputTel) {
        this.$message.warning('请输入电话号码');
        return false;
      } else if (!pattern.test(this.inputTel)) {
        this.$message.warning('请输入正确的手机号码');
        return false;
      }
      const strarea = this.$refs['cascader'].getCheckedNodes()[0].pathLabels;// 获取所在地区的名字lable
      const data = {
        area: strarea.join('-'),
        bigAreaId: this.inputCity[0],
        joinSource: source,
        name: this.inputName,
        phone: this.inputTel
      };
      contactUs(data)
        .then(res => {
          if (res.code === '0') {
            this.$message({
              message: '感谢您的关注，我们将于近期与您联系！',
              type: 'success'
            });
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: 'warning'
          });
        });
    }
  }

};
</script>

<style  scope lang="scss">
@media screen and (min-width: 1400px) {
  .join-promotion{
    width: 100%;
    height: auto;
    .join-list{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .temporary{
        width: 1920px;
        height: auto;
      }
    }
    .join-us{
      width: 100%;
      height: auto;
      margin: 0 auto;
      background: rgba(65, 177, 230, 0.23);
      // position: fixed;
      // left: 0;
      // bottom: 0;
      // z-index: 11;
      // transition: bottom .3s;
      .join-center{
        width: 1400px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding: 24px 0 18px;
        justify-content: space-between;
        .input-to{
          flex: 1;
          display: flex;
          align-items: center;
          margin-left: 50px;
          .el-input__inner{
            border: none;
            height: 48px;
            background: #FFFFFF;
            border-radius: 10px;
          }
          .el-input{
            margin-right: 20px;
          }
          .name-input{
              width:195px;
          }
          .tel-input{
              width:243px;
          }
          .intention{
              width:auto;
          }
        }
        h4{
          font-size: 26px;
          font-weight: bold;
          color: #41B1E6;
        }
        .el-button--primary{
          width: 243px;
          height: 48px;
          background: #41B1E6;
          border-radius: 10px;
          border: none;
        }

      }
    }
    .is_fixed{
      position: fixed;
      top: 0;
      left: 50%;
      // margin-left: -250px;
      z-index: 999;
    }

  }
  .join-list-mb,.join-center-mb{
    display: none;
  }
}

@media screen and (max-width: 1400px) {
  .el-message{
    min-width: 280px;
  }
  .join-promotion{
    background: #fff;
  }
  .join-list,.join-us{
    display: none;
  }
  .join-list-mb{
    img{
      width: 100%;
      height: auto;
    }
  }
  .join-center-mb{
    background: rgba(243,250,254,1);
    padding: 1rem .8rem  1.8rem;
    h4{
      font-size: .8rem;
      font-weight: 600;
      color: #41B1E6;
      text-align: center;
    }
    .input-to{
      width: 100%;
      .input-box{
        width: 100%;
        display: flex;
        align-items: center;
        border: 1px solid rgba(191, 191, 191, 1);
        padding-left: .373rem;
        font-size: .48rem;
        margin: .373rem 0;
        span{
          display: inline-block;
          width: 1.6rem;
          font-size: 0.48rem;
          color: #434343;
          text-align-last: justify;
          text-justify: distribute;
        }
        span:after {
            content: " ";
            width: 1.6rem;
            display: inherit;
        }
        .com-input{
          flex: 1;
          .el-input__inner{
            border: none;
            font-size: 0.48rem;
            background-color:rgba(255, 255, 255, 0);
            padding:0;
          }
        }
      }
    }
    .el-button--primary{
      width: 100%;
      height: 1.28rem;
      background: #41B1E6;
      border-radius: .13rem;
      border: none;
      font-size: .64rem;
    }
  }
}

</style>
