/*
 * @Author: your name
 * @Date: 2020-04-28 17:56:25
 * @LastEditTime: 2020-12-31 11:23:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /NewCunwUaregoodWebSite/src/api/joinUs.js
 */
import request from '@/utils/request';

// 招商加盟-加入我们提交数据
export function contactUs(data) {
  return request({
    url: `/whitelist/contact/us/`,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    method: 'post',
    data
  });
}
//  获取省市区
export function getArea() {
  return request({
    url: `/whitelist/prov-city/`,
    method: 'get'
  });
}

// 新建文件夹
// export function addFolder(data) {
//   return request({
//     url: `/v1/cddirs/`,
//     headers: { 'Content-Type': 'application/json;charset=UTF-8' },
//     method: 'post',
//     data
//   });
// }

